/* eslint-disable @babel/new-cap */
import {
  DeviceManager,
  DEVICE_EVENTS,
  WCAudioInputLevel,
  WCAudioOutputLevel,
} from '@zoom/common-utils';
import {
  AUDIO_OPTIONS,
  AUDIO_OPTIONS_FROM_LOCAL,
} from '../constants/audioOptions';
import { queryBit, toggleBit, getVideoDPI } from '../utils';
import { PREVIEW_OPTIONS, CANVAS_ID } from '../constants';
import {
  VIDEO_OPTIONS,
  VIDEO_OPTIONS_FROM_LOCAL,
} from '../constants/videoOptions';
import axios from 'axios';
import qs from 'qs';
import { LOCAL_STORAGE_KEYS } from '../constants/localstorage-keys';
import * as AVNotifyMediaSDKTypes from '../constants/AVNotifyMediaSDKTypes';
import getDataManager from '../utils/getDataManager';
import i18n from '../constants/i18n';

const MIC_STATE = {
  NORMAL: 'normal',
  RECORDING: 'recording',
  PLAYING: 'playing',
};

class SettingsTransaction {
  static initialState = {
    // video
    cameras: [],
    activeCamera: null,

    // audio
    microphones: [],
    speakers: [],
    activeSpeaker: null,
    activeMicrophone: null,
    isSpeakerPlaying: false,
    supportOutput: true,
    supportInput: true,
    micState: MIC_STATE.NORMAL,
    inputLevel: 0,
    outputLevel: 0,
    speakerVolume: sessionStorage.getItem('speaker-volume') || 50,

    [AUDIO_OPTIONS.AUTO_JOIN_AUDIO]: false,
    [AUDIO_OPTIONS.MUTE_MIC_WHEN_JOIN]: false,
    [AUDIO_OPTIONS.HOLD_SPACE_TO_UNMUTE]: false,
    [AUDIO_OPTIONS.SYNC_BUTTONS_ON_HEADSET]: false,
  };
  static reducer(state, action) {
    switch (action.type) {
      case 'update-devices': {
        const devices = action.payload;
        return {
          ...state,
          ...devices,
        };
      }
      case 'set-speaker-playing': {
        const isSpeakerPlaying = action.payload;
        return {
          ...state,
          isSpeakerPlaying,
        };
      }
      case 'set-mic-state': {
        const micState = action.payload;
        return {
          ...state,
          micState,
        };
      }
      case 'set-support-output': {
        const supportOutput = action.payload;
        return {
          ...state,
          supportOutput,
        };
      }
      case 'set-support-input': {
        const supportInput = action.payload;
        return {
          ...state,
          supportInput,
        };
      }
      case 'set-input-level': {
        const inputLevel = action.payload;
        return {
          ...state,
          inputLevel,
        };
      }
      case 'set-output-level': {
        const outputLevel = action.payload;
        return {
          ...state,
          outputLevel,
        };
      }
      case 'stop-speaker-playing': {
        return {
          ...state,
          isSpeakerPlaying: false,
          outputLevel: 0,
        };
      }
      case 'init-options': {
        return {
          ...state,
          ...action.payload,
        };
      }
      case 'set-option-value': {
        return {
          ...state,
          ...action.payload,
        };
      }
      case 'reset-audio': {
        return {
          ...state,
          isSpeakerPlaying: false,
          outputLevel: 0,
          inputLevel: 0,
          micState: MIC_STATE.NORMAL,
        };
      }
      case 'set-speaker-volume': {
        return {
          ...state,
          speakerVolume: action.payload,
        };
      }
      default:
        return state;
    }
  }

  constructor(
    dispatch,
    {
      defaultPreviewOptions,
      baseUrl,
      ringUrl,
      wcHash,
      onChange,
      type,
      initialState,
    },
  ) {
    this._dispatch = dispatch;
    this._properties = {
      defaultPreviewOptions,
      baseUrl,
      ringUrl,
      wcHash,
      onChange,
      type,
    };
    this._dataManager = getDataManager();
    this._deviceManager = new DeviceManager({
      shouldListenDeviceChange: true,
      labelForDefaultDevice: i18n.DefaultDeviceLabel,
      getCache: (key) => {
        return this._dataManager.getValue(key);
      },
      setCache: (key, value) => {
        this._dataManager.setValue(key, value);
      },
    });
    this._deviceManager.init();
    const handleDeviceChange = (deviceState) => {
      this.dispatch({ type: 'update-devices', payload: deviceState });
      const {
        activeSpeaker: prevActiveSpeaker,
        activeMicrophone: prevActiveMicrophone,
      } = this._state;
      const { activeSpeaker, activeMicrophone } = deviceState;
      if (activeSpeaker !== prevActiveSpeaker) {
        this.changeSpeaker(activeSpeaker);
      }
      if (activeMicrophone !== prevActiveMicrophone) {
        this.changeMicrophone(activeMicrophone);
      }
    };
    this._deviceManager.on(DEVICE_EVENTS.DEVICE_CHANGED, handleDeviceChange);
    this._deviceManager.on(DEVICE_EVENTS.DEVICE_SELECTED, handleDeviceChange);
    this._deviceManager.watchInitComplete().then(handleDeviceChange);

    this._initAudioUtilities();
    this._initOptions();
    // this._socketInstance = new JsMediaSDK_Instance({});
    // this._socketInstance.JsMediaSDK_PreLoad({}, () => {}, {
    // isTeslaMode: isTeslaMode(),
    // resourceManager: IResourceManager,
    // file: [
    //   {
    //     path: `${getBaseStaticUrl()}/vb-resource/vbbuffer.bin`,
    //     type: 'bin',
    //   },
    //   {
    //     path: `${getBaseStaticUrl()}/vb-resource/tf.min.js`,
    //     type: 'js',
    //   },
    // ],
    // });
    this._mediaStream = null;
    this._state = initialState;
  }

  _getOptionValueFromLocal(optionKey) {
    const localKey = LOCAL_STORAGE_KEYS[optionKey];
    return this._dataManager.getValue(localKey);
  }

  _saveOptionValueToLocal(optionKey, optionValue) {
    const localKey = LOCAL_STORAGE_KEYS[optionKey];
    this._dataManager.setValue(localKey, optionValue);
  }

  _initOptions() {
    const { defaultPreviewOptions } = this._properties;
    const initialStateFromPreviewOptions = {
      [AUDIO_OPTIONS.AUTO_JOIN_AUDIO]: queryBit(
        defaultPreviewOptions,
        PREVIEW_OPTIONS.AUTO_JOIN_AUDIO,
      ),
      [AUDIO_OPTIONS.MUTE_MIC_WHEN_JOIN]: !queryBit(
        defaultPreviewOptions,
        PREVIEW_OPTIONS.AUDIO_ON,
      ),
      [VIDEO_OPTIONS.TURN_OFF_VIDEO_WHEN_JOIN]: !queryBit(
        defaultPreviewOptions,
        PREVIEW_OPTIONS.VIDEO_ON,
      ),
    };

    const initialStateFromLocal = {};
    for (const option in AUDIO_OPTIONS_FROM_LOCAL) {
      const optionKey = AUDIO_OPTIONS_FROM_LOCAL[option];
      Object.assign(initialStateFromLocal, {
        [optionKey]: this._getOptionValueFromLocal(optionKey),
      });
    }
    for (const option in VIDEO_OPTIONS_FROM_LOCAL) {
      const optionKey = VIDEO_OPTIONS_FROM_LOCAL[option];
      Object.assign(initialStateFromLocal, {
        [optionKey]: this._getOptionValueFromLocal(optionKey),
      });
    }

    this.dispatch({
      type: 'init-options',
      payload: {
        ...initialStateFromPreviewOptions,
        ...initialStateFromLocal,
      },
    });
  }

  _initAudioUtilities() {
    this._wcOutputLevel = new WCAudioOutputLevel({
      analyserCallback: (level) => {
        this.dispatch({ type: 'set-output-level', payload: level });
      },
    });
    this._wcInputLevel = new WCAudioInputLevel({
      analyserCallback: (level) => {
        this.dispatch({ type: 'set-input-level', payload: level });
      },
    });
    if (!WCAudioOutputLevel.IsBrowserSupport()) {
      this.dispatch({ type: 'set-support-output', payload: false });
    }
    if (!WCAudioInputLevel.IsBrowserSupport()) {
      this.dispatch({ type: 'set-support-input', payload: false });
    }
  }

  watchInitComplete() {
    return this._deviceManager.watchInitComplete();
  }

  startInputLevel() {
    const { activeMicrophone, activeSpeaker } = this._state;
    this._wcInputLevel
      .start(activeMicrophone)
      .then(() => {
        if (WCAudioInputLevel.IsBrowserSupportChangeSpeaker()) {
          this._wcInputLevel.changeSpeaker(activeSpeaker);
        }
        this._deviceManager.emit(DEVICE_EVENTS.DEVICE_PERMISSION_MAY_GRANT);
      })
      .catch((e) => {
        if (e.message.includes('Permission denied')) {
          // todo set not allowed
        }
      });
  }

  stopInputLevel() {
    this._wcInputLevel.stop();
  }

  dispatch(action) {
    if (this._dispatch) {
      this._dispatch(action);
    }
  }

  changeSpeaker({ deviceId }) {
    const { onChange } = this._properties;
    this._deviceManager.manuallySelectSpeaker(deviceId);
    this._wcInputLevel.changeSpeaker(deviceId);
    this._wcOutputLevel.changeSpeaker(deviceId);
    this.dispatch({ type: 'set-speaker-playing', payload: false });
    if (onChange) {
      onChange({ 'active-speaker': deviceId });
    }
  }

  changeMicrophone({ deviceId }) {
    const { onChange } = this._properties;
    this._deviceManager.manuallySelectMicrophone(deviceId);
    this._wcInputLevel.changeMicrophone(deviceId);
    if (onChange) {
      onChange({ 'active-microphone': deviceId });
    }
  }

  testSpeaker() {
    const { isSpeakerPlaying, activeSpeaker, micState } = this._state;
    const { ringUrl } = this._properties;
    if (isSpeakerPlaying) {
      this._wcOutputLevel.stop();
      this.dispatch({ type: 'stop-speaker-playing' });
    } else {
      const audioUrl = ringUrl;
      fetch(audioUrl)
        .then((data) => data.arrayBuffer())
        .then((data) => {
          this._wcOutputLevel
            .start(data, activeSpeaker)
            .then(() => {
              this.dispatch({ type: 'set-speaker-playing', payload: true });
            })
            .catch((e) => {
              if (e.name === 'CHANGE_SPEAKER_ERROR') {
                this.dispatch({ type: 'set-speaker-playing', payload: true });
              } else {
                throw e;
              }
            });
        });
      if (micState !== MIC_STATE.NORMAL) {
        this.dispatch({ type: 'set-mic-state', payload: MIC_STATE.NORMAL });
        this._wcInputLevel.stop();
      }
    }
  }

  syncState(state) {
    this._state = state;
  }

  testMic() {
    const { micState, isSpeakerPlaying } = this._state;
    if (micState === MIC_STATE.NORMAL) {
      this._wcInputLevel
        .startRecord()
        .then(({ recordPromise, playRecordPromise }) => {
          this.dispatch({
            type: 'set-mic-state',
            payload: MIC_STATE.RECORDING,
          });
          recordPromise.then(() => {
            this.dispatch({
              type: 'set-mic-state',
              payload: MIC_STATE.PLAYING,
            });
          });
          playRecordPromise.then(() => {
            this.dispatch({
              type: 'set-mic-state',
              payload: MIC_STATE.NORMAL,
            });
          });
        });
      if (isSpeakerPlaying) {
        this._wcOutputLevel.stop();
        this.dispatch({ type: 'stop-speaker-playing' });
      }
    } else if (micState === MIC_STATE.RECORDING) {
      this._wcInputLevel.stopRecord();
    } else if (micState === MIC_STATE.PLAYING) {
      this._wcInputLevel.audioRecorder.stopAudioPlayer();
    }
  }

  destroy() {
    this._wcOutputLevel.destroy();
    this._wcInputLevel.destroy();
  }

  clearAudioState() {
    this._wcOutputLevel.stop();
    this._wcInputLevel.stop();
    this.dispatch({ type: 'reset-audio' });
  }

  getOptionValue(optionKey) {
    return this._state[optionKey];
  }

  _postPreviewOptions(previewOptions) {
    const { wcHash, baseUrl, type } = this._properties;

    const url = `${baseUrl}/wc/change_setting`;
    const data = {
      type: type,
      hash: wcHash,
      avOptions: previewOptions,
    };

    return (
      axios
        .post(url, qs.stringify(data))
        .then(({ data: resData }) => {
          if (!resData.status) {
            // eslint-disable-next-line no-console
            console.error(resData.errorMessage);
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error))
    );
  }

  _getPreviewOptions() {
    const isAutoJoinAudio = this._state[AUDIO_OPTIONS.AUTO_JOIN_AUDIO];
    const isAutoMuteAudio = this._state[AUDIO_OPTIONS.MUTE_MIC_WHEN_JOIN];
    const isAutoCloseVideo =
      this._state[VIDEO_OPTIONS.TURN_OFF_VIDEO_WHEN_JOIN];

    let previewOptions = 0;
    if (isAutoJoinAudio) {
      previewOptions += PREVIEW_OPTIONS.AUTO_JOIN_AUDIO;
    }
    if (!isAutoMuteAudio) {
      previewOptions += PREVIEW_OPTIONS.AUDIO_ON;
    }
    if (!isAutoCloseVideo) {
      previewOptions += PREVIEW_OPTIONS.VIDEO_ON;
    }
    return previewOptions;
  }

  _changePreviewOptions(optionKey, optionValue) {
    const optionMap = {
      [AUDIO_OPTIONS.AUTO_JOIN_AUDIO]: PREVIEW_OPTIONS.AUTO_JOIN_AUDIO,
      [AUDIO_OPTIONS.MUTE_MIC_WHEN_JOIN]: PREVIEW_OPTIONS.AUDIO_ON,
      [VIDEO_OPTIONS.TURN_OFF_VIDEO_WHEN_JOIN]: PREVIEW_OPTIONS.VIDEO_ON,
    };
    const previewOptions = this._getPreviewOptions();
    const newPreviewOptions = toggleBit(previewOptions, optionMap[optionKey]);
    this._postPreviewOptions(newPreviewOptions).then(() => {
      this.dispatch({
        type: 'set-option-value',
        payload: { [optionKey]: optionValue },
      });
    });
  }

  _setSpeakerVolume(volume) {
    sessionStorage.setItem('speaker-volume', volume);
    this.dispatch({
      type: 'set-speaker-volume',
      payload: volume,
    });
  }

  setOptionValue(optionKey, optionValue) {
    const { onChange } = this._properties;
    if (optionValue === this._state[optionKey]) {
      return;
    }
    switch (optionKey) {
      case AUDIO_OPTIONS.AUTO_JOIN_AUDIO:
      case AUDIO_OPTIONS.MUTE_MIC_WHEN_JOIN:
      case VIDEO_OPTIONS.TURN_OFF_VIDEO_WHEN_JOIN: {
        this._changePreviewOptions(optionKey, optionValue);
        break;
      }
      case AUDIO_OPTIONS.SYNC_BUTTONS_ON_HEADSET: {
        this._saveOptionValueToLocal(optionKey, optionValue);
        this.dispatch({
          type: 'set-option-value',
          payload: { [optionKey]: optionValue },
        });
        break;
      }
      default: {
        this._saveOptionValueToLocal(optionKey, optionValue);
        this.dispatch({
          type: 'set-option-value',
          payload: { [optionKey]: optionValue },
        });
        break;
      }
    }
    if (onChange) {
      onChange({ [optionKey]: optionValue });
    }
  }

  notifySDK(type, data) {
    this._socketInstance.Notify_MeidaSDK(type, data);
  }

  startVideo() {
    const dpiObject = getVideoDPI();
    this.notifySDK(AVNotifyMediaSDKTypes.VIDEO_MASK_SETTING, {
      canvas: CANVAS_ID,
      maskdom: 0,
      bgdom: null,
      dx: 0,
      dy: 0,
      width: dpiObject.width,
      height: dpiObject.height,
      ssid: 0,
      VideoSelectValue: null,
    });
  }

  async startVideoWithoutSDK() {
    this._beforeStartVideo();
    this._pendingMediaPromise = navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    const stream = await this._pendingMediaPromise;
    this._mediaStream = stream;
    const videoEl = document.getElementById(CANVAS_ID);
    if (!videoEl) {
      return;
    }
    videoEl.srcObject = stream;
  }

  _beforeStartVideo() {
    this.stopVideoWithoutSDK();
  }

  _stopMediaStream(stream) {
    const tracks = stream.getVideoTracks();
    tracks.forEach((track) => track.stop());
  }

  stopVideoWithoutSDK() {
    if (this._mediaStream) {
      this._stopMediaStream(this._mediaStream);
      this._mediaStream = null;
    }
    if (this._pendingMediaPromise) {
      this._pendingMediaPromise.then(this._stopMediaStream);
      this._pendingMediaPromise = null;
    }
  }

  stopVideo() {
    this.notifySDK(AVNotifyMediaSDKTypes.FINISH_MASK_SETTING, {
      isSwitch: false,
    });
  }

  getActiveSpeaker() {
    const { speakers, activeSpeaker } = this._state;
    return speakers.find((speaker) => speaker.deviceId === activeSpeaker);
  }

  getActiveMicrophone() {
    const { microphones, activeMicrophone } = this._state;
    return microphones.find((mic) => mic.deviceId === activeMicrophone);
  }

  getSpeakerVolume() {
    const { speakerVolume } = this._state;
    return speakerVolume;
  }
}

export default SettingsTransaction;
