import Mitt, { Emitter } from 'mitt';
import { isEmpty } from 'lodash';

const UNKNOWN_MIC = 'Unrecognized microphone';
const UNKNOWN_SPEAKER = 'Unrecognized speaker';
const UNKNOWN_CAMERA = 'Unrecognized camera';
export const EVENTS = {
  INIT_SUCCESS: 'INIT_SUCCESS',
  INIT_FAIL: 'INIT_FAIL',
  DEVICE_CHANGED: 'DEVICE_CHANGED',
  DEVICE_PERMISSION_MAY_GRANT: 'DEVICE_PERMISSION_MAY_GRANT',
  DEVICE_SELECTED: 'DEVICE_SELECTED',
  HANDLE_DEVICE_CHANGED: 'HANDLE_DEVICE_CHANGED',
};

const STORAGE_KEY = {
  selectedMicrophoneId: 'activeMicrophoneDeviceId',
  selectedMicrophoneLabel: 'activeMicrophoneLabel',
  selectedSpeakerId: 'activeSpeakerDeviceId',
  selectedSpeakerLabel: 'activeSpeakerLabel',
  selectedCameraId: 'activeCameraDeviceId',
  selectedCameraLabel: 'activeCameraDeviceLabel',
};

type DeviceKind = 'audioinput' | 'audiooutput' | 'videoinput';
export interface Device {
  kind: DeviceKind;
  label: string;
  deviceId: string;
}

type SetCacheFn = (k: string, v: string) => void
type GetCacheFn = (k: string) => string | null
interface IOptions {
  labelForDefaultDevice?: string,
  isExternalMode: boolean,
  shouldListenDeviceChange: boolean,
  setCache: SetCacheFn,
  getCache: GetCacheFn,
}

const defaultOptions: IOptions = {
  labelForDefaultDevice: 'default',
  isExternalMode: false,
  shouldListenDeviceChange: true,
  setCache: (k, v) => localStorage.setItem(k, v),
  getCache: (k) => localStorage.getItem(k)
}

function assertIsDevice(device: Device | undefined): asserts device is Device {
  if (device == null) throw new Error('device not exist')
}

class DeviceManager {
  private _eb: Emitter<Record<string, string>>;
  private _labelForDefaultDevice: any;
  private _deviceMap: Map<string, Device>;
  private _microphoneIdList: string[];
  private _speakerIdList: string[];
  private _cameraIdList: string[];
  private _activeMicrophone: string;
  private _activeSpeaker: string;
  private _activeCamera: string;
  private _initComplete: boolean;
  private _isMicrophoneAuthorized: boolean;
  private _isCameraAuthorized: boolean;
  private _isExternalMode: boolean;
  private _shouldListenDeviceChange: boolean;
  private _setCache: SetCacheFn;
  private _getCache: GetCacheFn;

  constructor(options: IOptions) {
    const _options = Object.assign({}, defaultOptions, options)
    this._eb = Mitt();

    this._labelForDefaultDevice = _options.labelForDefaultDevice;
    this._setCache = _options.setCache;
    this._getCache = _options.getCache;
    this._deviceMap = new Map();
    this._microphoneIdList = [];
    this._speakerIdList = [];
    this._cameraIdList = [];
    this._activeMicrophone = 'default';
    this._activeSpeaker = 'default';
    this._activeCamera = 'default';

    this._initComplete = false;
    this._isMicrophoneAuthorized = false;
    this._isCameraAuthorized = false;
    this._isExternalMode = Boolean(_options.isExternalMode)
    this._shouldListenDeviceChange = _options.shouldListenDeviceChange;
  }

  isMicDeviceValid(deviceId: string, deviceLabel: string) {
    const preCondition =
      this._isExternalMode || deviceId !== 'communications';
    return (
      preCondition &&
      !/ZoomAudioDevice/i.test(deviceLabel) &&
      !/Zoom-\S*/.test(deviceLabel) &&
      !/CubebAggregateDevice\S*/.test(deviceLabel) &&
      !/Microsoft Teams Audio/i.test(deviceLabel)
    );
  }

  isSpeakerDeviceValid(deviceId: string, deviceLabel: string) {
    const preCondition =
      this._isExternalMode || deviceId !== 'communications';
    return preCondition &&
     !/ZoomAudioDevice/i.test(deviceLabel) &&
     !/Microsoft Teams Audio/i.test(deviceLabel);
  }

  static getDeviceKey(kind: string, deviceId: string) {
    return `${kind}:${deviceId}`;
  }

  static isSameDevice(deviceA: Device, deviceB: Device) {
    if (!deviceA || !deviceB) {
      return false;
    }
    return (
      deviceA.deviceId === deviceB.deviceId && deviceA.label === deviceB.label
    );
  }

  init() {
    if ('ondevicechange' in navigator.mediaDevices && this._shouldListenDeviceChange) {
      navigator.mediaDevices.ondevicechange = () => {
        this._handleDeviceChange()
      };
    }

    this._enumerateDevice()
      .then((devices) => {
        this._updateDevices(devices);
        this._updateActiveDevices();
        this.emit(EVENTS.INIT_SUCCESS, this.getDeviceState());
      })
      .catch((err) => {
        this.emit(EVENTS.INIT_FAIL, err);
      })
      .finally(() => {
        this._initComplete = true;
      });

    this.on(EVENTS.DEVICE_PERMISSION_MAY_GRANT, () => {
      if (!this._isMicrophoneAuthorized || !this._isCameraAuthorized) {
        this._enumerateDevice().then((devices) => {
          this._updateDevices(devices);
          if (this._isMicrophoneAuthorized || this._isCameraAuthorized) {
            this.emit(EVENTS.DEVICE_CHANGED, this.getDeviceState());
          }
        });
      }
    });

    this.on(EVENTS.HANDLE_DEVICE_CHANGED, () => {
      this._handleDeviceChange()
    })
  }

  _handleDeviceChange() {
    this._enumerateDevice().then((devices) => {
      this._updateDevices(devices);
      this._updateActiveDevices();
      this.emit(EVENTS.DEVICE_CHANGED, this.getDeviceState());
    });
  }

  _enumerateDevice() {
    if (typeof navigator.mediaDevices !== 'object') {
      return Promise.resolve([]);
    }
    return navigator.mediaDevices.enumerateDevices();
  }

  _updateDevices(devices: Device[]) {
    this._microphoneIdList = [];
    this._speakerIdList = [];
    this._cameraIdList = [];
    this._deviceMap.clear();

    let defaultMicrophone, defaultSpeaker, defaultCamera;

    devices.forEach((device) => {
      const { kind, deviceId, label } = device;
      const deviceObj: Device = { kind, deviceId, label };
      // firefox sometimes enumerate duplicated device
      if (this._hasDevice(kind, deviceId)) {
        return;
      }
      if (
        kind === 'audioinput' &&
        this.isMicDeviceValid(deviceId, label)
      ) {
        this._microphoneIdList.push(deviceId);
        if (!isEmpty(deviceId) && !isEmpty(label)) {
          this._isMicrophoneAuthorized = true;
        }
        if (deviceId === 'default') {
          defaultMicrophone = deviceId;
        }
        if (!deviceObj.label) {
          if (deviceId === 'default') {
            deviceObj.label = this._labelForDefaultDevice;
          } else {
            deviceObj.label = `${UNKNOWN_MIC}${this._microphoneIdList.length}`;
          }
        }
      }
      if (
        kind === 'audiooutput' &&
        this.isSpeakerDeviceValid(deviceId, label)
      ) {
        this._speakerIdList.push(deviceId);
        if (deviceId === 'default') {
          defaultSpeaker = deviceId;
        }
        if (!deviceObj.label) {
          if (deviceId === 'default') {
            deviceObj.label = this._labelForDefaultDevice 
          } else {
            deviceObj.label = `${UNKNOWN_SPEAKER}${this._speakerIdList.length}`;
          }
        }
      }
      if (kind === 'videoinput') {
        this._cameraIdList.push(deviceId);
        if (deviceId === 'default') {
          defaultCamera = deviceId;
        }
        if (!isEmpty(deviceId) && !isEmpty(label)) {
          this._isCameraAuthorized = true;
        }
        if (!deviceObj.label) {
          if (deviceId === 'default') {
            deviceObj.label = this._labelForDefaultDevice;
          } else {
            deviceObj.label = `${UNKNOWN_CAMERA}${this._cameraIdList.length}`;
          }
        }
      }

      this._setDevice(kind, deviceId, deviceObj);
    });

    if (!defaultMicrophone) {
      this._setDevice('audioinput', 'default', {
        kind: 'audioinput',
        deviceId: 'default',
        label: this._labelForDefaultDevice,
      });
      this._microphoneIdList.unshift('default');
    }
    if (!defaultSpeaker) {
      this._setDevice('audiooutput', 'default', {
        kind: 'audiooutput',
        deviceId: 'default',
        label: this._labelForDefaultDevice,
      });
      this._speakerIdList.unshift('default');
    }
    if (!defaultCamera) {
      this._setDevice('videoinput', 'default', {
        kind: 'videoinput',
        deviceId: 'default',
        label: this._labelForDefaultDevice,
      });
      this._cameraIdList.unshift('default');
    }
  }

  _updateActiveDevices() {
    const kinds: DeviceKind[] = ['audioinput', 'audiooutput', 'videoinput'];
    kinds.forEach((kind) => {
      const activeDeviceId = this._getActiveDeviceIdByKind(kind);
      const selectedDevice = this._getManuallySelectedDevice(kind);
      const deviceIdList = this._getDeviceListByKind(kind);

      if (selectedDevice && this._hasDevice(kind, selectedDevice.deviceId)) {
        this._setActiveDeviceIdByKind(kind, selectedDevice.deviceId);
      } else if (!this._hasDevice(kind, activeDeviceId)) {
        this._setActiveDeviceIdByKind(kind, deviceIdList[0]);
      }
    });
  }

  getDeviceState() {
    return {
      microphones: this._microphoneIdList.map((deviceId) =>
        this._getDevice('audioinput', deviceId),
      ),
      speakers: this._speakerIdList.map((deviceId) =>
        this._getDevice('audiooutput', deviceId),
      ),
      cameras: this._cameraIdList.map((deviceId) =>
        this._getDevice('videoinput', deviceId),
      ),
      activeMicrophone: this._activeMicrophone,
      activeSpeaker: this._activeSpeaker,
      activeCamera: this._activeCamera,
    };
  }

  isInitComplete() {
    return this._initComplete;
  }

  emit(event: string, payload?: any) {
    this._eb.emit(event, payload);
  }

  on(event: string, handler: (payload: any) => void) {
    this._eb.on(event, handler);
  }

  off(event: string, handler: () => void) {
    this._eb.off(event, handler);
  }

  _setManuallySelectedDevice(kind: DeviceKind, deviceId: string) {
    if (!this._hasDevice(kind, deviceId)) {
      throw Error(`device with id ${deviceId} not exist!`);
    }
    const device = this._getDevice(kind, deviceId);
    if (device.kind === 'audioinput') {
      this._setCache(
        STORAGE_KEY.selectedMicrophoneId,
        deviceId,
      );
      this._setCache(
        STORAGE_KEY.selectedMicrophoneLabel,
        device.label,
      );
    } else if (device.kind === 'audiooutput') {
      this._setCache(
        STORAGE_KEY.selectedSpeakerId,
        deviceId,
      );
      this._setCache(
        STORAGE_KEY.selectedSpeakerLabel,
        device.label,
      );
    } else if (device.kind === 'videoinput') {
      this._setCache(
        STORAGE_KEY.selectedCameraId,
        deviceId,
      );
      this._setCache(
        STORAGE_KEY.selectedCameraLabel,
        device.label,
      );
    }
  }

  _getManuallySelectedDevice(kind: DeviceKind) {
    let deviceId, label;
    if (kind === 'audioinput') {
      deviceId = this._getCache(STORAGE_KEY.selectedMicrophoneId);
      label = this._getCache(STORAGE_KEY.selectedMicrophoneLabel);
    } else if (kind === 'audiooutput') {
      deviceId = this._getCache(STORAGE_KEY.selectedSpeakerId);
      label = this._getCache(STORAGE_KEY.selectedSpeakerLabel);
    } else if (kind === 'videoinput') {
      deviceId = this._getCache(STORAGE_KEY.selectedCameraId);
      label = this._getCache(STORAGE_KEY.selectedCameraLabel);
    }
    if (!deviceId || !label) {
      return null;
    }
    return { deviceId, label };
  }

  manuallySelectSpeaker(deviceId: string) {
    this._setManuallySelectedDevice('audiooutput', deviceId);
    this._activeSpeaker = deviceId;
    this.emit(EVENTS.DEVICE_SELECTED, { activeSpeaker: deviceId });
  }

  manuallySelectMicrophone(deviceId: string) {
    this._setManuallySelectedDevice('audioinput', deviceId);
    this._activeMicrophone = deviceId;
    this.emit(EVENTS.DEVICE_SELECTED, { activeMicrophone: deviceId });
  }

  manuallySelectCamera(deviceId: string) {
    this._setManuallySelectedDevice('videoinput', deviceId);
    this._activeCamera = deviceId;
    this.emit(EVENTS.DEVICE_SELECTED, { activeCamera: deviceId });
  }

  _getDeviceListByKind(kind: DeviceKind) {
    if (kind === 'audioinput') {
      return this._microphoneIdList;
    } else if (kind === 'audiooutput') {
      return this._speakerIdList;
    } else if (kind === 'videoinput') {
      return this._cameraIdList;
    } else {
      return [];
    }
  }

  _getActiveDeviceIdByKind(kind: DeviceKind) {
    if (kind === 'audioinput') {
      return this._activeMicrophone;
    } else if (kind === 'audiooutput') {
      return this._activeSpeaker;
    } else if (kind === 'videoinput') {
      return this._activeCamera;
    } else {
      return '';
    }
  }

  _setActiveDeviceIdByKind(kind: DeviceKind, deviceId: string) {
    if (kind === 'audioinput') {
      this._activeMicrophone = deviceId;
    } else if (kind === 'audiooutput') {
      this._activeSpeaker = deviceId;
    } else if (kind === 'videoinput') {
      this._activeCamera = deviceId;
    }
  }

  _hasDevice(kind: DeviceKind, deviceId: string) {
    return this._deviceMap.has(DeviceManager.getDeviceKey(kind, deviceId));
  }

  _getDevice(kind: DeviceKind, deviceId: string): Device {
    const device = this._deviceMap.get(DeviceManager.getDeviceKey(kind, deviceId));
    assertIsDevice(device)
    return device;
  }

  _setDevice(kind: DeviceKind, deviceId: string, device: Device) {
    this._deviceMap.set(DeviceManager.getDeviceKey(kind, deviceId), device);
  }

  watchInitComplete() {
    return new Promise((resolve, reject) => {
      if (this.isInitComplete()) {
        resolve(this.getDeviceState());
      } else {
        this.on(EVENTS.INIT_SUCCESS, resolve);
        this.on(EVENTS.INIT_FAIL, reject);
      }
    });
  }
}

export default DeviceManager;
