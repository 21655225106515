/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useMemo, useEffect } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@zoom/zoom-react-ui';
import Checkbox from '../checkbox';
import Select from 'react-select';
import AudioProgress from '../audio-progress/AudioProgress';
import I18n from '../../constants/i18n';
import { audioOptionsConfig } from '../../constants/audioOptions';
import SettingsContext from '../../context/SettingsContext';
import './AudioPane.scss';
import { isMSFTMode, isMTRAndroid } from '../../utils';
import '@zoom/zoom-react-ui/lib/index.css';
import Slider from '../slider';

const MIC_STATE = {
  NORMAL: 'normal',
  RECORDING: 'recording',
  PLAYING: 'playing',
};
const isSupportTestSpeaker = !(isMSFTMode() && !isMTRAndroid());
const AudioPane = (props) => {
  const { className } = props;
  const [isTestSpeakerTipOpen, setTestSpeakerTipOpen] = useState(false);
  const [isTestMicTipOpen, setTestMicTipOpen] = useState(false);
  const [isSelectSpeakerTipOpen, setSelectSpeakerTipOpen] = useState(false);
  const [isSelectMicTipOpen, setSelectMicTipOpen] = useState(false);
  const ctx = useContext(SettingsContext);
  const { state, transaction } = ctx;
  const {
    microphones,
    speakers,
    activeMicrophone,
    activeSpeaker,
    outputLevel,
    inputLevel,
    isSpeakerPlaying,
    supportOutput,
    supportInput,
    micState,
    speakerVolume,
  } = state;

  const speakerOptions = useMemo(() => {
    return speakers.map((item) => ({
      label: item.label,
      value: item.deviceId,
    }));
  }, [speakers]);

  const selectedSpeaker = useMemo(() => {
    return speakerOptions.find((item) => item.value === activeSpeaker);
  }, [speakerOptions, activeSpeaker]);

  const micOptions = useMemo(() => {
    return microphones.map((item) => ({
      label: item.label,
      value: item.deviceId,
    }));
  }, [microphones]);

  const selectedMicrophone = useMemo(() => {
    return micOptions.find((item) => item.value === activeMicrophone);
  }, [micOptions, activeMicrophone]);

  const handleVolumeChange = (value) => {
    transaction._setSpeakerVolume(value);
  };

  useEffect(() => {
    transaction.watchInitComplete().then(() => {
      transaction.startInputLevel();
    });
    return () => {
      transaction.clearAudioState();
    };
  }, []);

  const TestSpeakerContent = (
    <>
      <div className={`settings-title`}>{I18n.Speaker}</div>
      <div className={`settings-config-row`}>
        <Tooltip
          id="settings-test-speaker-tip"
          position="top-middle"
          contentNode={<span>{I18n.SpeakerTip}</span>}
          paperClassName="zm-settings-tip"
          role="tooltip"
          isOpen={isTestSpeakerTipOpen}
        >
          <Button
            onClick={() => {
              transaction.testSpeaker();
            }}
            className="settings-config-left settings-button"
            aria-labelledby="settings-test-speaker-tip"
            onMouseOver={() => setTestSpeakerTipOpen(true)}
            onMouseOut={() => setTestSpeakerTipOpen(false)}
            onFocus={() => setTestSpeakerTipOpen(true)}
            onBlur={() => setTestSpeakerTipOpen(false)}
          >
            {isSpeakerPlaying ? I18n.Stop : I18n.TestSpeaker}
          </Button>
        </Tooltip>
        <Tooltip
          position="top-middle"
          contentNode={<span>{I18n.SpeakerSelectTip}</span>}
          paperClassName={`zm-settings-tip`}
          isOpen={isSelectSpeakerTipOpen}
          role="tooltip"
          id="settings-select-speaker-tip"
        >
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <div
            className="settings-config-right"
            onMouseOver={(e) => {
              if (e.currentTarget.contains(e.target)) {
                setSelectSpeakerTipOpen(true);
              }
            }}
            onMouseOut={() => setSelectSpeakerTipOpen(false)}
          >
            <Select
              classNamePrefix="react-select"
              options={speakerOptions}
              value={selectedSpeaker}
              isSearchable={false}
              onChange={({ label, value }) =>
                transaction.changeSpeaker({ deviceId: value, label })
              }
              menuPortalTarget={document.body}
              onFocus={() => setSelectSpeakerTipOpen(true)}
              onBlur={() => setSelectSpeakerTipOpen(false)}
              aria-label={`${I18n.Speaker}, ${selectedSpeaker?.label}, ${I18n.SpeakerSelectTip}`}
            />
          </div>
        </Tooltip>
      </div>
      <div className="settings-config-row">
        <label htmlFor="volume" className="settings-config-left">
          {I18n.Volume}
        </label>
        <Slider
          className="settings-config-right"
          value={speakerVolume}
          onChange={handleVolumeChange}
        />
      </div>
      {supportOutput && (
        <div className="settings-config-row">
          <div
            id="audio-output-label"
            className="settings-config-left settings-config-label"
          >
            {I18n.OutputLevel}:
          </div>
          <AudioProgress
            level={outputLevel}
            aria-labelledby="audio-output-label"
          />
        </div>
      )}
    </>
  );

  const micText = {
    [MIC_STATE.NORMAL]: I18n.TestMic,
    [MIC_STATE.RECORDING]: I18n.Recording,
    [MIC_STATE.PLAYING]: I18n.Playing,
  }[micState];

  const testMicContent = (
    <>
      <div className="settings-title">{I18n.Microphone}</div>
      <div className="settings-config-row">
        <Tooltip
          position="top-middle"
          paperClassName="zm-settings-tip"
          contentNode={<span>{I18n.MicTip}</span>}
          isOpen={isTestMicTipOpen}
          role="tooltip"
          id="tooltip-test-mic"
        >
          <Button
            onClick={() => transaction.testMic()}
            className="settings-config-left settings-button"
            aria-labelledby="tooltip-test-mic"
            onMouseOver={() => setTestMicTipOpen(true)}
            onMouseOut={() => setTestMicTipOpen(false)}
            onFocus={() => setTestMicTipOpen(true)}
            onBlur={() => setTestMicTipOpen(false)}
          >
            {micText}
          </Button>
        </Tooltip>
        <Tooltip
          position="top-middle"
          paperClassName="zm-settings-tip"
          contentNode={<span>{I18n.MicSelectTip}</span>}
          isOpen={isSelectMicTipOpen}
          role="tooltip"
          id="tooltip-select-mic"
        >
          {/*eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <div
            className="settings-config-right"
            onMouseOver={(e) => {
              if (e.currentTarget.contains(e.target)) {
                setSelectMicTipOpen(true);
              }
            }}
            onMouseOut={() => setSelectMicTipOpen(false)}
          >
            <Select
              classNamePrefix="react-select"
              value={selectedMicrophone}
              isSearchable={false}
              onChange={({ value }) =>
                transaction.changeMicrophone({ deviceId: value })
              }
              menuPortalTarget={document.body}
              options={micOptions}
              onFocus={() => setSelectMicTipOpen(true)}
              onBlur={() => setSelectMicTipOpen(false)}
              aria-label={`${I18n.Microphone}, ${selectedMicrophone?.label}, ${I18n.MicSelectTip}`}
            />
          </div>
        </Tooltip>
      </div>
      {supportInput && (
        <div className="settings-config-row">
          <div
            className="settings-config-left settings-config-label"
            id="audio-input-label"
          >
            {I18n.InputLevel}:
          </div>
          <AudioProgress
            level={inputLevel}
            aria-labelledby="audio-input-label"
          />
        </div>
      )}
    </>
  );

  const OptionsContent = audioOptionsConfig.map((option) => {
    if (!option.enable) {
      return null;
    }
    return (
      <Checkbox
        key={option.key}
        className="settings-config-checkbox"
        checked={transaction.getOptionValue(option.key)}
        onChange={(checked) => {
          transaction.setOptionValue(option.key, checked);
        }}
      >
        {option.desc}
      </Checkbox>
    );
  });

  return (
    <div className={clsx('zm-audio-pane', className)}>
      {isSupportTestSpeaker && TestSpeakerContent}
      {testMicContent}
      {OptionsContent}
    </div>
  );
};

AudioPane.propTypes = {
  className: PropTypes.string,
};

export default AudioPane;
