import { isExternalControlledMode } from '../utils';
import i18n from './i18n';

export const AUDIO_OPTIONS_FROM_PO = {
  AUTO_JOIN_AUDIO: 'auto-join-audio',
  MUTE_MIC_WHEN_JOIN: 'mute-mic-when-join',
};

export const AUDIO_OPTIONS_FROM_LOCAL = {
  HOLD_SPACE_TO_UNMUTE: 'hold-space-to-unmute',
  SYNC_BUTTONS_ON_HEADSET: 'sync-buttons-on-headset',
};
export const AUDIO_OPTIONS = {
  ...AUDIO_OPTIONS_FROM_PO,
  ...AUDIO_OPTIONS_FROM_LOCAL,
};

export const audioOptionsConfig = [
  {
    key: AUDIO_OPTIONS.MUTE_MIC_WHEN_JOIN,
    desc: i18n.MuteMicWhenJoin,
    enable: !isExternalControlledMode(),
  },
  {
    key: AUDIO_OPTIONS.HOLD_SPACE_TO_UNMUTE,
    desc: i18n.HoldSpaceToUnmute,
    enable: !isExternalControlledMode(),
  },
  {
    key: AUDIO_OPTIONS.SYNC_BUTTONS_ON_HEADSET,
    desc: i18n.SyncButtonsOnHeadSet,
    enable:
      !isExternalControlledMode() &&
      window.navigator.hid &&
      window.navigator.hid.requestDevice,
  },
];
